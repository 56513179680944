import { Countdown } from '@brainfinance/icash-component-library';
import * as React from 'react';
import { postMessageSender } from '../../../Helpers/PostMessage';

type CountdownContractExpirePropsType = {
  date: Date;
};

function CountdownContractExpire(props: CountdownContractExpirePropsType) {
  return (
    <Countdown
      date={props.date}
      className='sm:items-center'
      onTimeout={() => {
        postMessageSender({
          status: 'timeout',
          data: {
            message:
              "Your contract has expired. Please complete a new application by clicking on the 'Start application' button on the Home page.",
          },
        });
      }}
    >
      Hurry! Your contract expires in:
    </Countdown>
  );
}

export default CountdownContractExpire;
