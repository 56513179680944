import type { NavigateFunction } from 'react-router-dom';

export const LOAN_AGREEMENT_READY_PATH = '';
export const REVIEW_DOCUMENTS_PATH = '/review-documents';
export const AJUST_YOUR_FINAL_PAYMENT_DATE = '/ajust-your-final-payment-date';

export const InstantLoanRoutes = [{ path: LOAN_AGREEMENT_READY_PATH }, { path: REVIEW_DOCUMENTS_PATH }, {path: AJUST_YOUR_FINAL_PAYMENT_DATE}];

export const goToView = (navigate: NavigateFunction, path: typeof InstantLoanRoutes[number]['path']) => {
  navigate(path);
};
